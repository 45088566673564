.profiles {
    margin-bottom: 50px;
}

.list-title {
    border-bottom: 2px solid lightskyblue;
    padding-bottom: 1px;
    display: inline;
    /*color: darkgray;*/
    color: #4b4b4b;
}

.cards-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
