.list {
    margin-bottom: 50px;
}

.list-title {
    /*border-bottom: 2px solid lightskyblue;*/
    /*padding-bottom: 1px;*/
    display: inline;
    color: #4b4b4b;
    margin-right: 5px;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
}

.cards-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.add-button {
    border: 1px solid #19CECB;
    background-color: rgba(25, 206, 203, 0.4);
    font-size: 15px;
    line-height: 30px;
    width: 185px;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 15px;
}
