.scrolling-projects-bar {
    /*height: 60px;*/
    position: fixed;
    bottom: 0;
    left: 0;
    right: -3000px;
    height: 50px;
    overflow: hidden;
    color: white;

}

.project-wrapper{
    float: left;
    padding-left: 10px;
    padding-right: 20px;
    line-height: 50px;
    height: 100%;
    display: block;
}

.project-icon {
    float: left;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center center;
}

.project-pill {
    width: 350px;
    float: left;

    position: relative;
    margin-left: 5px;
    padding-right: 10px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 900;
    overflow: hidden;
    cursor: pointer;
    color: white;
}

.scrolling-projects-bar ion-fab-button {
    height: 34px;
    width: 34px;
    margin: 0;
    float: left;
}

.scrolling-projects-bar ion-icon {
    height: 20px;
}
