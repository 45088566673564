.home-wrapper {
    min-height: calc(100vh - 80px);
}

.slider {
    /*margin-bottom: 30px;*/
    /*position: absolute;*/
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    /*height: 100%;*/
    overflow: hidden;


    height: calc(100vh - 80px);
    position: fixed;
    top: 80px;
}

.slide-1 {
    width: 100%;
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-image: url(/assets/imgs/slide01.jpg);
    background-size: cover;
}

.slide-2 {
    width: 100%;
    background-image: url(/assets/imgs/slide02.jpg);
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-size: cover;
}

.slide-3 {
    width: 100%;
    background-image: url(/assets/imgs/slide03.jpg);
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-size: cover;
}

.slide-4 {
    width: 100%;
    background-image: url(/assets/imgs/slide04.jpg);
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-size: cover;
}

.slide-5 {
    width: 100%;
    background-image: url(/assets/imgs/slide05.jpg);
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-size: cover;
}

.slide-6 {
    width: 100%;
    background-image: url(/assets/imgs/slide06.jpg);
    height: calc(100vh - 80px);
    /*height: 100%;*/
    background-size: cover;
}

.column {
    text-align: justify;
    /*padding-top: 30px;*/
    color: white;
    position: relative;
    padding-top: 70px;
}

.column-content {
    max-width: 600px;
    position: relative;
    margin: 0 0 20px 0;
}

.column h3 {
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    font-size: 37px;
    margin-bottom: 30px;
}

.column-1 {
    font-size: 18px;
}

.column-1 p {
    font-weight: lighter;
}

.link-button {
    width: 100%;
}

.column-2 {
    padding-left: 30px;
}

.icon-row {
    margin-bottom: 50px;
}

.column-2 .home-icon {
    width: 80px;
    height: 80px;
    background-color: white;
    float: left;
    border-radius: 50%;
    margin-right: 25px;
    margin-bottom: 10px;
    background-size: 60%;
    background-position: center center;
    background-repeat: no-repeat;
}

.column-2 .home-icon-1 {
    background-image: url(/assets/imgs/home-offer.png);
}

.column-2 .home-icon-2 {
    background-image: url(/assets/imgs/home-need.png);
}

.column-2 .home-icon-3 {
    background-image: url(/assets/imgs/home-support.png);
}

.column-2 h4 {
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin: 0;
}

.column-2 p {
    font-size: 14px;
    line-height: 16px;
    margin-top: 10px;
}

.main-content-wrapper {
    /*height: calc(100vh - 80px);*/
    width: 100%;
}

.column-title {
    border-bottom: 2px solid lightskyblue;
    padding-bottom: 1px;
    display: inline;
    color: #004153;
    /*color: white;*/
    cursor: pointer;
    /*text-shadow: 0 0 20px black;*/
    font-size: 35px;
    hyphens: auto;
}

.column-content {
    font-size: 18px;
}

.row-hero {
    background-image: url(/assets/imgs/trianglify.png);
    background-size: cover;
    height: 140px;
    margin-top: -3px;
}

.hero-title {
    font-size: 40px;
    color: #004153;
}

.description {
    text-align: center;
    font-size: 25px;
    margin: 40px 0;
}

.highlight {
    color: #004153;
    font-weight: bold;
}


.blue-gradient {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1b4052+0,15232a+100 */
    background: #1b4052; /* Old browsers */
    background: -moz-linear-gradient(-45deg,  #1b4052 0%, #15232a 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  #1b4052 0%,#15232a 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  #1b4052 0%,#15232a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b4052', endColorstr='#15232a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    /*position: absolute;*/
    opacity: 0.8;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}


.footer {
    /*position: absolute;*/
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    background-color: rgba(21, 35, 42, 0.78);
    color: var(--ion-color-primary,#3880ff);
    overflow: hidden;
    position: relative;
    z-index: 15;
}

.footer-line {
    line-height: 100px;
    text-align: right;
    font-size: 26px;
    margin: 0;
}

.footer a {
    color: white;
}


@media screen and (max-width: 720px) {
    .column-2 {
        padding-left: 5px;
    }
    .column-2 .home-icon {
        float: none;
        display: block;
        margin: 0 auto;
    }

    .column-2 h4 {
        text-align: center;
        margin-top: 10px;
    }
}
