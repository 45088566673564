.file-progress-container{
  padding: 15px;
}
.file-progress-wrapper{
  display: flex;
  align-items: center;
}

.error{
  color: red;
}


.zone-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.zone-wrapper{
  width: 90%;
  height: 50%;
  max-height: 250px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
