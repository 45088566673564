.profile-view {
    max-width: 800px;
    margin: 50px auto 0px;
}

.profile-header-card {
    display: flex;
    flex-direction: row;
}

.project-icon {
    border-radius: 50%;
    height: 43px;
    width: 43px;
    margin: 0 auto;
    position: relative;
    background-position: center center;
    background-size: 60%;
    background-repeat: no-repeat;
    top: 9px;
    display: inline-block;
}

.round-card {
    border-radius: 5px;
    border: 1px solid #F0F0F0;
    padding: 30px;
}

.round-card::after {
    display: block;
    content: "";
    clear: both;
}

.project-name {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
    display: inline-block;
    margin-right: 15px;
}

.project-type {
    border-radius: 3px;
    text-transform: uppercase;
    width: 245px;
    text-align: center;
    font-size: 15px;
    height: 28px;
    font-weight: 900;
    border: 1px solid transparent;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    top: -3px;
    margin-bottom: 15px;
}

.project-subtitle {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 900;
    color: #033C51;
}

.project-description {
    font-size: 13px;
    line-height: 18px;
}

.project-area {
    border-radius: 3px;
    text-transform: uppercase;
    padding: 0 10px;
    text-align: center;
    font-size: 15px;
    height: 28px;
    font-weight: 900;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
    color: white;
    margin-right: 20px;
}

.round-card-data {
    border-radius: 5px;
    border: 1px solid #F0F0F0;
    padding: 0 30px;
}

.round-card-data::after {
    display: block;
    content: "";
    clear: both;
}

.project-datum {
    border-bottom: 1px solid #F0F0F0;
    padding: 30px 0;
    box-sizing: border-box;
    overflow: auto;
}

.project-datum-label {
    float: left;
    width: 50%;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 900;
    color: #3E3E3E;
}

.project-datum-value {
    float: left;
    width: 50%;
    font-size: 13px;
    line-height: 18px;
    color: #3E3E3E;
}

.trl-image {
    width: 150px;
    height: 150px;
    margin: 30px 30px 30px;
    display: inline;
    vertical-align: middle;
}


.profile-header-image {
    width: 300px !important;
    height: 300px;
    display: inline !important;
    float: left;
    border-radius: 5px;
    margin-right: 60px;
    object-fit: cover;
    object-position: center;
}

.action-button {
    cursor: pointer;
    border: 1px solid #ffce00;
    background-color: #ffce00;
    border-radius: 3px;
    text-transform: uppercase;
    padding: 0 10px;
    text-align: center;
    font-size: 15px;
    height: 28px;
    font-weight: 900;
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    color: white;
    margin-right: 20px;
}

.action-button.inactive {
    border-color: #DDDDDD;
    color: #DDDDDD;
    background-color: #F1F1F1;
}




.profile-header-content-info {
    flex: 1;
    padding: 10px;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
}

.profile-header-content-info h2 {
    border-bottom: 2px solid lightskyblue;
    padding-bottom: 1px;
    display: inline;
}
.profile-header-content-info label {
    margin-top: 5px;
}

.profile-header-actions {
    margin-left: 7px;
    margin-right: 7px;
}

.details-section {
    margin-top: 75px;
}

.details-section label{
    margin-left: 10px;
}

.detail-item {
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid lightgrey;
    display: flex;
    align-items: center;
}

.detail-item div {
    flex: 1;
}

.detail-item:last-of-type {
    padding-bottom: 0px;
    border-bottom: 0px;
}

.friendship-request-suggestion {
    color: #3E3E3E;
    font-style: italic;
    text-align: center;
    margin: 40px 0;
}


@media screen and (max-width: 500px) {
    .profile-header-card {
        flex-direction: column;
        max-width: 200px;
    }

    .detail-item {
        flex-direction: column;
    }
}


@media screen and (max-width: 720px) {


    .profile-header-image {
        float: none;
        display: block;
        margin: 0 auto 15px;
    }

    .project-datum-label,
    .project-datum-value{
        float: none;
        width: 100%;
    }

    .trl-image {
        display: block;
        margin: 15px auto;
    }
}


.help-button {
    position: fixed;
    bottom: 60px;
    right: 30px;
    z-index: 100;
}
