.outer-content {
    --background: white;
}


.header {
    color:  var( --ion-color-primary, lightgray);
}
.logo-wrapper{
    padding: 5px;
    margin-left: 5px;
}


.customIconContainer{
    margin-right: 27px;

}

.ionIcon{
    color: #47C6C7;
}


.privacy-policy {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    text-align: center;
}
