.filters-container {
    padding: 10px 10px 27px;
}

.filters-container div {
    float: left;
    margin-right: 30px;
}

.filters-container div label {
    text-transform: uppercase;
    color: #BCBCBC;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 35px;
    top: -3px;
    position: relative;
}

.area-filters-container {
    border: 1px solid #19CECB;
    border-radius: 3px;
    overflow: hidden;
    padding: 15px 15px 15px 170px;
    position: relative;
}

.area-filters-title {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 160px;
    text-transform: uppercase;
    background-color: #19CECB;
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.area-filters-title span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 20px;
}

.area-filters-container span {
    white-space: nowrap;
}

.area-filters-container span label {
    text-transform: uppercase;
    color: #BCBCBC;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 35px;
    top: -3px;
    position: relative;
}

.sponsor-logo {
    height: 21px;
    margin-bottom: -3px;
    margin-left: 10px;
}


.external-link {
    background-color: var(--ion-color-primary,#3880ff);
    color: white;
    border-radius: 3px;
    padding: 5px 11px;
    text-transform: uppercase;
    display: inline-block;
    line-height: 16px;
    font-size: 15px;
    margin-left: 10px;
}


h3 {
    text-align: center;
    color: var(--ion-color-primary,#3880ff);
    text-transform: uppercase;
    font-weight: bold;
}

h2 {
    font-weight: bold;
}

.sponsor-logo {
    text-align: center;
}

.centered-text {
    text-align: center;
}

.centered-subtitle {
    color: #033C51;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
}
