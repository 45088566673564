.chart-wrapper {
    height: 100px;
    position: relative;
}

.totals-chart-card {
    max-height: 200px;
}

.totals-chart-card canvas {
    width: 100% !important;
    height: 100px !important;
}
