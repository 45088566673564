.wrapper {
    position: relative;
}

.project-card {
    width: 285px;
    height: 350px;
    min-width: 250px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
}

.remove-icon {
    position: absolute;
    bottom: 5px;
    right: 40px;
    font-size: 30px;
    cursor: pointer;
}

.accept-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 30px;
    cursor: pointer;
}

.project-title {
    /*border-bottom: 2px solid lightskyblue;*/
    text-transform: uppercase;
    padding-bottom: 1px;
    margin: 0;
    /*color: darkgray;*/
    color: white;
    cursor: pointer;
    text-shadow: 0 0 20px black;
    font-size: 22px;
    hyphens: auto;
    text-align: center;
    font-weight: bold;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    color: white;
    height: 100%;
    width: 100%;
    padding: 15px;
}

.project-icon {
    border-radius: 50%;
    height: 43px;
    width: 43px;
    margin: 0 auto;
    /*position: relative;*/
    background-position: center center;
    background-size: 60%;
    background-repeat: no-repeat;
    position: absolute;
    top: 145px;
    left: 0;
    right: 0;
}

.project-icon-image {
    position: absolute;
    width: 80%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

}

.project-image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.project-type {
    border-radius: 3px;
    width: 90%;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    height: 28px;
    font-weight: bold;
    border: 2px solid transparent;
    box-sizing: border-box;
    text-shadow: 0 0 20px black;
    top: 200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.promoted {
    width: 47px;
    height: 36px;
    background-color: #FED72F;
    position: absolute;
    left: 0;
    top: 80px;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;
    line-height: 36px;
}

.description {

    text-shadow: 0 0 20px black;
    font-size: 14px;
    line-height: 16px;
    height: 110px;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px 15px;
    hyphens: auto;
    text-align: justify;
    margin: 0;
}

.read-more {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
}
