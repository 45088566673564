.about-content h2 {
    text-transform: uppercase;
    color: #A7CDC9;
    text-align: center;
    font-weight: 900;
}

.center {
    text-align: center;
}

.section {
    width: 100%;
    position: relative;
    padding: 30px 15px;
}

.section .section-content {
    max-width: 1080px;
    position: relative;
    margin: auto;
}

.section-blue {
    background-color: #F5FAFA;
    box-shadow: inset 0 0 10px grey;
}

.type-column {
    padding: 0 15px;
    text-align: center;
}

.type-column img {
    max-width: 180px;
    margin: 30px 0;
}

.type-box {
    width: 100%;
    background-color: #E8F3F3;
    padding: 10px 0;
    text-align: center;
    margin-top: 10px;
}

.type-box div {
    text-transform: uppercase;
    font-weight: 900;
    color: #A7C7C4;
    padding: 3px 0;
}


.user-column img {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    display: block;
    width: 100%;
}

.ott-column {
    background-color: #ECF8F7;
    color: #1b4052;
    padding: 20px;
}

.ott-column h3 {
    text-transform: uppercase;
    font-weight: 900;
    text-align: center;
    margin-bottom: 15px;
}

.ott-column p {
    text-align: justify;
}
