.login-content {
    --background: url(/assets/imgs/bg-login.jpg) top center / cover no-repeat;
    /*--background: green !important;*/
    /*background: url('http://www.redott.mx/img/banner.jpg');*/
    /*background: red !important;*/
    height: 100%;
}

.pass-recovery {
    text-align: end;
    font-size: 11px !important;
    color:  var( --ion-color-primary, lightgray);;
    cursor: pointer;
}

.bg-overlay {
    background: rgba(36, 36, 36, 0.6);
    height: 100%;
}


.middle-column {
    min-width: 280px;
}

.button-divider {
    color:  var( --ion-color-primary, lightgray);
    margin-top: 5px;
    margin-bottom: 5px;
}

.login-card{
    background-color: white;
    padding: 10px 23px;
    color:  var( --ion-color-primary, lightgray);
    border-radius: 8px;
}

.buttons-wrapper{
    padding: 0px 30px;
    margin-top: 35px;
}
.card-input{
    margin: 15px 0px;
}
