body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.full-height{
 /*height: 100%;*/
  padding-bottom: 50px !important;
}

.full-width{
  width: 100% !important;
}

.text-center{
  text-align: center;
}

.unset-max-width{
  max-width: unset !important;
}

.flex-1{
  flex: 1 !important;
}

a {
  text-decoration: none;
}


.bkg-overlay.sc-onboarding-form {
  filter: blur(100px);
}

.basic-question {
  display: block;
  min-height: 240px;
  transition: 0.7s all;
  opacity: 0.2;
  padding: 36px 0;
  box-sizing: border-box;
}
.basic-question .wrapper {
  position: relative;
}
.basic-question.active {
  opacity: 1;
}
.basic-question.active .overlay {
  display: none;
}
.basic-question .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.basic-question label {
  font-size: 24px !important;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
}
.basic-question label span {
  display: flex;
  align-items: center;
}
.basic-question p {
  margin: 0;
  font-size: 20px !important;
  color: #BCBCBC;
  font-weight: lighter;
  font-style: italic;
  opacity: 0.7;
  white-space: pre-line;
}

[slot] * {
  visibility: inherit !important;
}

.basic-question .option {
  border: 1px solid white;
  background-color: rgba(0, 58, 81, 0.6);
}

.basic-question .option input {
  display: none;
}

.basic-question .option label {
  font-size: 15px !important;
  font-weight: 900;
  text-transform: none;
}

.basic-question .option .text {
  font-size: 15px !important;
  font-weight: 900;
  text-transform: none;
  color: white;
}

.sc-omf-next-question {
  color: white;
}

.hint.sc-omf-textarea-question {
  color: white;
}

.basic-question input,
.basic-question textarea {
  color: white;
}

/* React slick */

.slick-next {
  right: 15px !important;
  z-index: 10;
}

.slick-prev {
  left: 15px !important;
  z-index: 10;
}

.section-title {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 900;
  color: #033C51;
  margin-bottom: 10px;
  padding-left: 10px;
}

.section-subtitle {
  font-style: italic;
  color: #BCBCBC;
  font-weight: bold;
  font-size: 15px;
  margin-top: 0;
}

.option.selected.sc-omf-radio-question .code.sc-omf-radio-question,
.option.selected.sc-omf-select-question .code.sc-omf-select-question {
  /*background-color: #003a51;*/
  background-color: #19CECB;
  /*color: #ebf0f2;*/
  color: black;
}

.sc-omf-control-bar-h button[disabled].sc-omf-control-bar {
  cursor: not-allowed;
  background-color: #BCBCBC !important;
  border: 1px solid #BCBCBC !important;
  color: #9A9A9A !important;
  border: 0;
}

.sc-omf-control-bar-h .btn.sc-omf-control-bar {
  display: inline-block;
  background-color: #14B2AB !important;
  font-size: 16px;
  border: 1px solid #14B2AB !important;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 12px 0 rgba(0,0,0,.1);
  box-shadow: 0 3px 12px 0 rgba(0,0,0,.1);
  color: white;
  padding: 7px 8px;
  min-width: 40px;
  margin-left: 10px;
  height: 40px;
  cursor: pointer;
}

.btn.sc-omf-next-question {
  background-color: #14B2AB !important;
  border: 1px solid #14B2AB !important;
}

.btn.sc-omf-questionnaire {
  background-color: #14B2AB !important;
  border: 1px solid #14B2AB !important;
}

.active.sc-omf-textarea-question-h textarea.sc-omf-textarea-question,
.active.sc-omf-text-question-h input.sc-omf-text-question {
  border-color: white;
}

input.sc-omf-text-question:focus {
  border-color: white !important;
}


.blue-gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#1b4052+0,15232a+100 */
  background: #1b4052; /* Old browsers */
  background: -moz-linear-gradient(-45deg,  #1b4052 0%, #15232a 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg,  #1b4052 0%,#15232a 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg,  #1b4052 0%,#15232a 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1b4052', endColorstr='#15232a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}




.ag-header-cell,
.ag-header-row {
  background-color: #E2F6F5 !important;
}


a.active {
  border-left: 5px solid red;
  width: 100%;
  display: block;
}


.date-picker-col .react-datepicker-wrapper,
.date-picker-col .react-datepicker__input-container,
.date-picker-col input {
  width: 100%;
  height: 100%;
  display: block;
}
