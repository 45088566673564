.search-bar{
    max-width: 500px;
    margin: auto;
    --background: #E5E5E5;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.search-bar input {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
}

.header-container{
    background-color: #FDFEFD;
    color:  var( --ion-color-primary, lightgray);;
}

.logo-wrapper{
    padding: 5px;
    margin-left: 25px;
}



