.profile-card {
    width: 280px;
    height: 340px;
    min-width: 250px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 10px;
}

.profile-title {
    /*border-bottom: 2px solid lightskyblue;*/
    text-transform: uppercase;
    padding-bottom: 1px;
    margin: 0;
    /*color: darkgray;*/
    color: white;
    cursor: pointer;
    text-shadow: 0 0 20px black;
    font-size: 22px;
    hyphens: auto;
    text-align: center;
    font-weight: bold;
}

.overlay {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,0.5);
    color: white;
    height: 100%;
    width: 100%;
    padding: 15px;
}

.description {
    text-shadow: 0 0 20px black;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px 15px;
    hyphens: auto;
    text-align: justify;
    margin: 0;
}
