.filters-container {
    padding: 10px 10px 27px;
}

.filters-container div {
    float: left;
    margin-right: 30px;
}

.filters-container div label {
    text-transform: uppercase;
    color: #BCBCBC;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 35px;
    top: -3px;
    position: relative;
}

.area-filters-container {
    border: 1px solid #19CECB;
    border-radius: 3px;
    overflow: hidden;
    padding: 15px 15px 15px 170px;
    position: relative;
}

.area-filters-title {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    width: 160px;
    text-transform: uppercase;
    background-color: #19CECB;
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.area-filters-title span {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 20px;
}

.area-filters-container span {
    white-space: nowrap;
    display: inline-block;
}

.area-filters-container span label {
    text-transform: uppercase;
    color: #BCBCBC;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 30px;
    line-height: 35px;
    top: -3px;
    position: relative;
}


@media screen and (max-width: 720px) {

    .filters-container {
        padding: 10px 10px 27px;
    }

    .filters-container div {
        float: none;
        margin-right: 0;
    }

    .area-filters-title {
        position: initial;
        width: 100%;
        text-transform: uppercase;
        background-color: #19CECB;
        color: white;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }

    .area-filters-container {
        padding: 15px;
    }

    .area-filters-title span {
        position: initial;
        display: block;
        margin-bottom: 15px;
    }

    .area-filters-container span {
        white-space: normal;
        display: block;
    }

}
